import extend from 'extend';
import Dayjs from 'dayjs';

const TLBooking = function( options = {} ) {
  var global = window.TLBooking.global || null;

  //
  // Default by Travelanium
  //
  var defaults = {
    baseURL: 'https://reservation.travelanium.net/propertyibe2/',
    params: {
      propertyId: null,
      onlineId: 4,
      checkin: Dayjs().format('YYYY-MM-DD'),
      checkout: Dayjs().add(1, 'day').format('YYYY-MM-DD'),
      numofroom: 1,
      numofadult: 2,
      numofchild: 0,
      accesscode: null,
      lang: 'en',
      currency: 'THB',
      checkinauto: null,
      numofnight: null,
      pid: null,
      pgroup: null,
    },
    debug: false,
  }

  //
  // Merge form params with defaults
  //
  var configs = extend(true, {}, defaults, global, options);

  //
  // Decorate string with \Gogole Analytics tracking code
  //
  String.prototype.gaTracking = function() {
    var output = this;
    try {
      if (!ga) return output;
      ga(function (tracker) {
        if (tracker == undefined)
          tracker = ga.getAll()[0];
        if (!tracker) return output;
        if (!tracker.get('linkerParam')) return output;
        var linker = new window.gaplugins.Linker(tracker);
        output = linker.decorate(url);
      });
    } catch (e) {
      if (configs.debug) {
        console.info(e.message);
      }
    }
    return output;
  }

  var toArrElms = ( selector_elements ) => {
    var arr = [];
    if (selector_elements instanceof HTMLElement) {
      arr.push(selector_elements);
    } else if (selector_elements instanceof NodeList) {
      arr = Array.prototype.slice.call(selector_elements);
    } else if (selector_elements instanceof Array && selector_elements[0] instanceof HTMLElement) {
      arr = selector_elements;
    } else {
      arr = Array.prototype.slice.call(document.querySelectorAll(selector_elements));
    }
    return arr;
  }

  var Methods = {
    conf: configs,

    /**
     * url
     * @param {object} params
     * @param {string} baseURL default: https://reservation.travelanium.net/propertyibe2/
     *
     * @return {string} Generated booking url
     */
    url( params, baseURL ) {
      var base   = baseURL || this.conf.baseURL;
      var params = extend(true, {}, this.conf.params, params);
      var query  = [];

      for (let key in params) {
        let value = params[key];
        if (!!value || typeof value === 'number' && value === 0) {
          if (key === 'checkin' || key === 'checkout') {
            if (Date.parse(value)) {
              value = Dayjs(value).format('YYYY-MM-DD');
            }
          }

          query.push(`${key}=${value}`);
        }
      }

      var url = `${base}?${query.join('&')}`;

      return url.gaTracking();
    },

    /**
     * form
     * @param {string} selector 
     * @param {object} options 
     */
    form( selector, options ) {
      if (!selector) {
        return;
      }

      let defaults = {
        target: '_blank',
        beforeSubmit: () => {},
        afterSubmit: () => {},
        validate: () => true,
      }

      let settings = extend(true, {}, defaults, options);

      let elements = toArrElms(selector);

      elements.forEach(form => {
        form.setAttribute('target', settings.target);

        if (!form.hasAttribute('action')) {
          form.setAttribute('action', this.conf.baseURL);
        }

        let lastInput = null;
        let requireParams = Object.entries(this.conf.params).filter(([key, value]) => value !== null);
        requireParams = Object.fromEntries(requireParams);

        for (let key in requireParams) {
          let value = requireParams[key];
          let field = form.querySelector(`[name=${key}]`);
          if (!field || field.disabled) {
            let input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value;
            if (!lastInput) {
              form.insertAdjacentElement('afterbegin', input);
            } else {
              lastInput.insertAdjacentElement('afterend', input);
            }
            lastInput = input;
          }
        }

        form.addEventListener('submit', event => {
          event.preventDefault();

          settings.beforeSubmit(form, this);
          if (settings.validate(form, this)) {
            form.submit();
            settings.afterSubmit(form, this);
          }
        });
      });
    },

    /**
     * attr
     * @param {string} selectors
     * @param {string} attr - HTML attribute which you want to change
     * @param {object} params - booking parameters
     *
     * @return {object} methods for attribute
     */
    attr( selector, attribute, params ) {
      let self = this;

      if (!selector || !attribute) {
        return;
      }

      let elements = toArrElms(selector);
      
      var init = function() {
        var url = self.url( params );
        elements.forEach(element => {
          var oldValue = element.getAttribute(attribute);
          element.setAttribute(attribute, url);
          element["TLBooking"] = {
            oldValue: oldValue,
            value: url,
            update: init,
          }
        });
      }

      init();

      return {
        update: init,
      };
    },

    /**
     * href - Shorthand of href attribute
     * @param {string} selector
     *
     * @return {object} methods for attribute
     */
    href( selector, params ) {
      if (!selector) {
        return;
      }

      return this.attr( selector, 'href', params );
    },

    /**
     * setBaseURL - for change baseURL after init
     * @param {string} url - Base url default: https://reservation.travelanium.net/propertyibe2/
     *
     * @return void
     */
    setBaseURL( url ) {
      this.conf.baseURL = url;
    },

    /**
     * setParam - Shorthand for create/update default booking parameter
     * @param {string} name - Parameter name
     * @param {*} value - Parameter value
     *
     * @return void
     */
    setParam( name, value ) {
      this.conf.params[name] = value;
    },

    /**
     * getParam - Use to get parameter value 
     * @param {String} name - Parameter name
     *
     * @return mixed
     */
    getParam( name ) {
      return this.conf.params[name];
    }
  }

  return Methods;
}

export default TLBooking;